/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

code {
  font-size: 13px;
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fbefe2;
  color: #484858;
}