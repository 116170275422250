.footer {
  width: 100%;
  background-color: #141414;
  margin-top: 10%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer__content {
  margin: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: inherit;
}

.footer__item {
  color: rgba(255,255,255,.7);
  font-size: 1.2rem;
  text-decoration: none;
  margin-right: 1%;
  font-family: 'PT Sans', sans-serif;
}

.footer__links {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.footer__link:hover {
  color: rgba(255,255,255,1);
  transition: 0.1s ease-in-out;
}

.footer__copyright {
  font-size: 1.1rem;
  color: rgba(255,255,255,.7);
  letter-spacing: 0.3px;
  font-family: 'PT Sans', sans-serif;
}

.footer__right {
  display: flex;
  flex-direction: column;
  color: rgba(255,255,255,.7);
  font-size: 1.1rem;
  text-align: right;
  font-family: 'PT Sans', sans-serif;
}


@media (min-width: 320px) and (max-width: 680px) {
  .footer__content {
    flex-direction: column;
    justify-content: center;
  }

  .footer__left {
    text-align: center;
  }

  .footer__right {
    text-align: center;
  }
}