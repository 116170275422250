.home {
  margin: 0 10%;
  margin-top: 7%;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro__pic {
  border-radius: 50%;
  max-width: 160px;
  height: 160px;
}

.intro__text {
  text-align: center;
}

.intro__title {
  font-size: 1.25rem;
  line-height: 1.8;
}

.intro__name {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.4;
}

.projects {
  margin-top: 13%;
}

@media (min-width: 481px) and (max-width: 767px) {

  .intro {
    flex-direction: column;
  }

  .intro__pic {
    margin-right: 0;
  }

  .intro__text {
    text-align: center;
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .intro {
    flex-direction: column;
  }

  .intro__pic {
    margin-right: 0;
  }

  .intro__text {
    text-align: center;
  }

  .intro__title {
    font-size: 1rem;
  }

  .intro__name {
    font-size: 2.2rem;
  }

}