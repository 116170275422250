.individual-project {
  margin: 0 20%;
  line-height: 1.5;
}

.project__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5%;
  margin-bottom: 3%;
  align-items: center;
}

.data__title {
  color: rgba(72, 72, 72, 1);
}

.info__text {
  font-size: 18px;
  font-family: 'PT Sans', sans-serif;
}

.view__btn {
  text-decoration: none;
  color: #2980b9;
  font-weight: bold;
  transition: 0.2s ease;
}

.view__btn:hover {
  border-bottom: 3px solid #2980b9;
  padding-bottom: 2px;
}

.project__img-second {
  text-align: center;
  margin-bottom: 2%;
}

.second__img {
  width: 100%;
}

.tech__title {
  margin: 0 5%;
  margin-bottom: 2%;
  color: #484848;
}

.description__paragraph {
  margin: 0 5%;
  margin-bottom: 2%;
  font-family: 'PT Sans', sans-serif;
}


.view__more {
  margin: 0 5%;
  margin-top: 7%;
}

@media (min-width: 1441px) {

  .description__paragraph {
    font-size: 1.3rem;
  }

}

@media (min-width: 1025px) and (max-width: 1280px) {

  .individual-project {
    margin: 0 10%;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {

  .individual-project {
    margin: 0 2%;
  }

}

@media (min-width: 481px) and (max-width: 767px) {

  .individual-project {
    margin: 0;
  }

  .project__header {
    flex-direction: column;
    margin: 0 5%;
    margin-bottom: 5%;
    align-items: flex-start;
  }

  .data__title {
    font-size: 1.4rem;
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .individual-project {
    margin: 0;
  }

  .project__header {
    flex-direction: column;
    margin: 0 5%;
    margin-bottom: 5%;
    align-items: flex-start;
  }

  .data__title {
    font-size: 1.4rem;
  }

  .info__text {
    font-size: 16px;
  }

}