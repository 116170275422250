.container {
    height: 90px;
}

.navigation {
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10%;
}

.navigation__right {
    width: -webkit-fill-available;
}

.list__big {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}

.list__item {
    padding-right: 30px;
}

.list__item a:hover {
    border-bottom: 2px solid #68b4ff;
    opacity: 0.9;
}

.display-contents {
    display: contents;
}

.list__item a {
    text-decoration: none;
    color: black;
}

.navigation__home {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    font-weight: bold;
}

.logo {
    height: 80px;
    margin-top: 20px;
    transition: 0.3s;
    border-radius: 50%;
}

.logo:hover {
    transform: rotate(35deg);
    transition: 0.3s;
    background-color: #68b4ff;
}

.navigation__btn {
    border: none;
    background: none;
    font-size: 1.3rem;
    z-index: 1;
    cursor: pointer;
}

/* hidden menu styling */
.fullHeight {
    height: 100%;
    width: 100%;
    position: fixed; /* position - fixed: must be determined by 'left', 'right', 'top' values */
    top: 0px; /* Want it to start from the top left of the page to take up the full screen */
    left: 0px;
    z-index: 1;
    background: rgba(0, 0, 0, 1);
    cursor: ne-resize;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.close__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    margin-top: 40px;
    margin-right: 40px;
    color: white;
}

.hidden__container {
    height: 30%;
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.hidden__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
}

.hidden__btn {
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Roboto', sans-serif;
}

.hidden__btn:hover {
    border-bottom: 2px solid grey;
    padding-bottom: 5px;
    transition: 0.1s ease-in-out;
}

.hidden__contact {
    color: grey;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
    font-family: 'PT Sans', sans-serif;
}

.contact__header {
    color: white;
}

.info {
    list-style: none;
}

.noHeight {
    height: 0%;
    display: none;
}

.btn__container {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.social__btn {
    max-height: 40px;
}

@media (min-width: 481px) and (max-width: 767px) {
    .hidden__container {
        flex-direction: column;
        height: 60%;
        margin-left: 6%;
        align-items: flex-start;
    }

    .social__btn {
        max-height: 30px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .logo {
        height: 70px;
        margin-top: 25px;
    }

    .hidden__container {
        flex-direction: column;
        height: 60%;
        margin-left: 6%;
        align-items: flex-start;
    }

    .social__btn {
        max-height: 30px;
    }
}
