.resume {
  margin: 0 10%;
  overflow: hidden;
}

.resume__hero {
  background-image: url("../../assets/resume.jpg");
  background-size: cover;
}

.download {
  font-size: 1.4rem;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-top: 5%;
}

.resume__page {
  margin-top: 10%;
}

.projects__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%;
}

.project__box {
  margin-bottom: 5%;
  border-bottom: 1px solid grey;
  padding-bottom: 5%;
}

.job__title {
  font-weight: 900;
  font-size: 2rem;
}

.job__title, .project__name {
  font-family: 'Roboto', sans-serif;
}

.experience__date {
  font-family: 'Roboto', sans-serif;
}

.project__name {
  font-size: 1.2rem;
  margin-bottom: 1%;
}

.project__info {
  width: 70%;
  color: rgb(72, 72, 72);
  letter-spacing: 0.5px;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}

/* Skills Section */
.skills__title {
  margin-bottom: 1%;
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
}

.skills__tools {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.tools__header {
  margin-bottom: 1%;
}

.tools__div {
  margin-bottom: 2%;
}

.tech__header {
  font-size: 1.3rem;
  margin-bottom: 1%;
  font-family: 'Roboto', sans-serif;
}

.tools__list {
  list-style-type: none;
  margin-left: 1%;
  font-family: 'Roboto', sans-serif;
}

.tools__list li {
  width: 150%;
}

.tools__list li:before {
  content: "\2713\0020";
}

.education__title {
  border-top: 1px solid grey;
  margin-top: 5%;
  padding-top: 5%;
  margin-bottom: 2%;
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1024px) {

  .resume {
    margin: 0 5%;
  }

  .job__title {
    font-size: 1.7rem;
  }

}

@media (min-width: 481px) and (max-width: 767px) {

  .resume {
    margin: 0 5%;
  }

  .projects__top {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    margin-bottom: 3%;
  }

  .job__title {
    font-size: 1.5rem;
  }

  .project__info {
    width: 100%;
  }

  .skills__title {
    text-align: center;
  }

  .skills__tools {
    flex-direction: column;
    align-items: flex-start;
  }

  .tools__div {
    width: 100%;
    margin-bottom: 5%;
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .resume {
    margin: 0;
  }

  .download {
    font-size: 1.2rem;
  }

  .resume__page {
    margin: 0 2%;
  }

  .projects__top {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
  }

  .project__info {
    width: 100%;
    font-size: 13px;
  }


  .job__title {
    font-size: 1rem;
  }

  .project__name {
    margin-bottom: 5%;
  }

  .skills__title {
    text-align: center;
  }

  .skills__title {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 6%;
  }

  .skills__tools {
    margin: 0 6%;
    flex-direction: column;
  }

  .tools__div {
    margin-top: 4%;
    width: 100%;
  }

  .tech__header {
    margin-bottom: 5%;
  }

  .tools__list {
    margin-left: 0;
  }

  .education__title {
    font-size: 1.7rem;
  }

  .education__div {
    margin: 0 2%;
  }

}