.about {
  margin: 0 10%;
}

.hero {
  height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  width: 100%;
  background-image: url("../../assets//work.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 1%;
}

.hero h2 {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}


.about__intro {
  margin: 0px 20%;
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 250px;
}

.title {
  font-family: 'PT Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
}

.header__name {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 1.75rem;
}

.intro__paragraph {
  line-height: 1.87;
  color: #646464;
  margin-top: 2%;
  font-size: 1.1rem;
}

@media (min-width: 481px) and (max-width: 767px) {

  .about {
    margin: 0;
  }

  .about__intro {
    margin: 0 10%;
    margin-top: 6%;
  }

}

@media (min-width: 320px) and (max-width: 480px) {

  .about {
    margin: 0;
  }

  .about__intro {
    margin: 0 5%;
    margin-top: 6%;
  }

  .header__name {
    font-size: 1.25rem;
  }

  .intro__paragraph {
    font-size: 1rem;
  }

}